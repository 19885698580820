import { validateCpf, validateCnpj, validateEmail } from '../../../vendor/validation';
import { errorMsg } from '../messages';

const investorCaptchaField = 'investidor[g_recaptcha_response]';

export const INVESTIDOR_SIGNUP_TOKEN_RECEIVED = 'INVESTIDOR_SIGNUP_TOKEN_RECEIVED';

export function changeType(type) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_TYPE',
    payload: type,
  };
}

export function setReCaptchaToken(value) {
  return {
    type: INVESTIDOR_SIGNUP_TOKEN_RECEIVED,
    payload: value,
  };
}

export function changeEmail(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_EMAIL',
    payload: value,
  };
}

export function changeFullName(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_FULLNAME',
    payload: value,
  };
}
export function changeCompanyName(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_COMPANYNAME',
    payload: value,
  };
}

export function changeTradeName(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_TRADENAME',
    payload: value,
  };
}

export function changeCpf(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_CPF',
    payload: value,
  };
}

export function changeCnpj(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_CNPJ',
    payload: value,
  };
}

export function changePhone(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_PHONE',
    payload: value,
  };
}

export function changeRamal(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_RAMAL',
    payload: value,
  };
}

export function changeCellphone(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_CELLPHONE',
    payload: value,
  };
}

export function changePassword(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_PASSWORD',
    payload: value,
  };
}

export function changeConfirmation(value) {
  return {
    type: 'INVESTIDOR_SIGNUP_CHANGE_CONFIRMATION',
    payload: value,
  };
}

export function submitForm(
  event,
  type,
  email,
  fullName,
  companyName,
  tradeName,
  cpf,
  cnpj,
  phone,
  password,
  confirmation,
  reCaptchaToken,
) {
  const interceptedEvent = event;
  interceptedEvent.target[investorCaptchaField].value = reCaptchaToken;

  const payload = {};
  if (email.length === 0) {
    payload.emailError = errorMsg.empty;
  } else if (!validateEmail(email)) {
    payload.emailError = errorMsg.invalidFormat;
  }

  if (fullName.length === 0) {
    payload.fullNameError = errorMsg.empty;
  }
  if (type !== 'pf') {
    if (phone.length === 0) {
      payload.phoneError = errorMsg.empty;
    }
  }
  if (password.length === 0) {
    payload.passwordError = errorMsg.empty;
  } else if (password.length < 6) {
    payload.passwordError = errorMsg.shortPassword;
  } else if (password !== confirmation) {
    payload.passwordError = errorMsg.unconfirmedPassword;
  }

  if (type === 'pf') {
    if (cpf.length === 0) {
      payload.cpfError = errorMsg.empty;
    } else if (!validateCpf(cpf)) {
      payload.cpfError = errorMsg.invalidFormat;
    }
  } else {
    if (companyName.length === 0) {
      payload.companyNameError = errorMsg.empty;
    }
    if (tradeName.length === 0) {
      payload.tradeNameError = errorMsg.empty;
    }
    if (cnpj.length === 0) {
      payload.cnpjError = errorMsg.empty;
    } else if (!validateCnpj(cnpj)) {
      payload.cnpjError = errorMsg.invalidFormat;
    }
  }
  if (Object.keys(payload).length === 0) {
    interceptedEvent.target.submit();
    return {
      type: 'INVESTIDOR_SIGNUP_SUCESSFULL_SUBMIT',
    };
  }
  return {
    type: 'INVESTIDOR_SIGNUP_FAILED_SUBMIT',
    payload,
  };
}
