import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Card, Typography, TextField, Button, Select, MenuItem, Grid, Alert } from 'liber-salti';
import ReactOnRails from 'react-on-rails';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import LoginFooter from '../../../../common-components/LoginFooter';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';

import { FUNDER_LOGIN_PATH, FUNDER_PATH } from '../../paths';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  ActionsWrapper,
  InputsWrapper,
  HiddenForm,
  DescriptionWrapper,
  SelectStyle,
} from '../../styles';

const PF_USER_TYPE = 'pf';

const userTypes = {
  [PF_USER_TYPE]: 'Pessoa física',
  factoring: 'Factoring',
  fidc: 'FIDC',
  securitizadora: 'Securitizadora',
  banco: 'Banco',
  esc: 'Empresa simples de crédito',
};

const FunderRegister = ({ errors }) => {
  const csrfToken = ReactOnRails.authenticityToken();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formRef = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [recaptchaToken, setRecaptchaToken] = useState();
  const [userType, setUserType] = useState();

  const [pfInformation, setPfInformation] = useState({
    name: '',
    email: '',
    cpf: '',
  });
  const [pjInformation, setPjInformation] = useState({
    contactName: '',
    email: '',
    cnpj: '',
    name: '',
    tradeName: '',
  });
  const [commonInformation, setCommonInformation] = useState({
    phone: '',
    phoneExtension: '',
    cellphone: '',
  });
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const fieldsSize = useMemo(() => (isMobile ? 'large' : 'medium'), [isMobile]);

  const areFieldsValid = useMemo(() => {
    const { phone } = commonInformation;

    if (!phone) return false;

    if (userType === PF_USER_TYPE) {
      const { name, email, cpf } = pfInformation;

      if (!name || !email || !cpf) return false;
    } else {
      const { contactName, email, cnpj, name, tradeName } = pjInformation;

      if (!contactName || !email || !cnpj || !name || !tradeName) return false;
    }

    return true;
  }, [pfInformation, pjInformation, commonInformation]);

  const passwordErrorMessage = useMemo(() => {
    if (passwordConfirmation?.length > 0) {
      if (password?.length < 6) {
        return 'A senha deve ter no mínimo 6 caracteres.';
      }
      if (password !== passwordConfirmation) {
        return 'Verifique se as senhas conferem.';
      }
    }
    return undefined;
  }, [password, passwordConfirmation]);

  const confirmButtonDisabled = useMemo(
    () =>
      Boolean(passwordErrorMessage) ||
      password.length === 0 ||
      passwordConfirmation.length === 0 ||
      !areFieldsValid,
    [passwordErrorMessage, password, passwordConfirmation, areFieldsValid],
  );

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const handleReturn = () => {
    window.location.assign(FUNDER_LOGIN_PATH);
  };

  const handleChangePfInformation = (field, e) => {
    setPfInformation({ ...pfInformation, [field]: e.target.value });
  };

  const handleChangePjInformation = (field, e) => {
    setPjInformation({ ...pjInformation, [field]: e.target.value });
  };

  const handleChangeCommonInformation = (field, e) => {
    setCommonInformation({ ...commonInformation, [field]: e.target.value });
  };

  const setupRecaptcha = async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha();
    setRecaptchaToken(token);
  };

  useEffect(() => {
    setupRecaptcha();
  }, [executeRecaptcha]);

  return (
    <Container>
      <SelectStyle isMobile={isMobile} />
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Cadastrar como investidor
            </Typography>
            {errors && errors.length > 0 && (
              <DescriptionWrapper>
                <Alert severity="error" description={errors[0][1]} />
              </DescriptionWrapper>
            )}
            <InputsWrapper>
              <Select
                label="Tipo de cadastro"
                size={isMobile ? 'medium' : 'small'}
                value={[userTypes[userType]]}
                onChange={e => setUserType(e.target.value[0])}
                data-testid="TypeSelect"
              >
                {Object.entries(userTypes).map(([key, value]) => (
                  <MenuItem value={key} key={key} data-testid={`Option-${key}`}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {userType && (
                <>
                  {userType === PF_USER_TYPE ? (
                    <>
                      <TextField
                        label="Nome completo"
                        fullWidth
                        required
                        onChange={e => handleChangePfInformation('name', e)}
                        value={pfInformation.name}
                        size={fieldsSize}
                        data-testid="NameInput"
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        required
                        onChange={e => handleChangePfInformation('email', e)}
                        value={pfInformation.email}
                        size={fieldsSize}
                        data-testid="EmailInput"
                      />
                      <TextField
                        label="CPF"
                        maskOption="cpf"
                        fullWidth
                        required
                        onChange={e => handleChangePfInformation('cpf', e)}
                        value={pfInformation.cpf}
                        size={fieldsSize}
                        data-testid="DocumentInput"
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        label="Nome para contato"
                        fullWidth
                        required
                        onChange={e => handleChangePjInformation('contactName', e)}
                        value={pjInformation.contactName}
                        size={fieldsSize}
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        required
                        onChange={e => handleChangePjInformation('email', e)}
                        value={pjInformation.email}
                        size={fieldsSize}
                      />
                      <TextField
                        label="CNPJ"
                        maskOption="cnpj"
                        fullWidth
                        required
                        onChange={e => handleChangePjInformation('cnpj', e)}
                        value={pjInformation.cnpj}
                        size={fieldsSize}
                      />
                      <TextField
                        label="Razão social"
                        fullWidth
                        required
                        onChange={e => handleChangePjInformation('name', e)}
                        value={pjInformation.name}
                        size={fieldsSize}
                      />
                      <TextField
                        label="Nome fantasia"
                        fullWidth
                        required
                        onChange={e => handleChangePjInformation('tradeName', e)}
                        value={pjInformation.tradeName}
                        size={fieldsSize}
                      />
                    </>
                  )}
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Telefone"
                        fullWidth
                        required
                        maskOption="phone"
                        onChange={e => handleChangeCommonInformation('phone', e)}
                        value={commonInformation.phone}
                        size={fieldsSize}
                        data-testid="PhoneInput"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Ramal"
                        fullWidth
                        onChange={e => handleChangeCommonInformation('phoneExtension', e)}
                        value={commonInformation.phoneExtension}
                        size={fieldsSize}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    label="Celular"
                    fullWidth
                    maskOption="phone"
                    onChange={e => handleChangeCommonInformation('cellphone', e)}
                    value={commonInformation.cellphone}
                    size={fieldsSize}
                  />
                  <TextField
                    label="Criar senha"
                    fullWidth
                    type="password"
                    required
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    error={Boolean(passwordErrorMessage)}
                    size={fieldsSize}
                    data-testid="PasswordInput"
                  />
                  <TextField
                    label="Confirmar senha"
                    fullWidth
                    type="password"
                    required
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    value={passwordConfirmation}
                    error={Boolean(passwordErrorMessage)}
                    helperText={passwordErrorMessage}
                    size={fieldsSize}
                    data-testid="PasswordConfirmationInput"
                  />
                </>
              )}
            </InputsWrapper>
            <ActionsWrapper>
              <Button
                size="large"
                fullWidth
                onClick={() => handleSubmit()}
                disabled={confirmButtonDisabled}
                data-testid="SubmitButton"
              >
                CADASTRAR
              </Button>
              <Button onClick={() => handleReturn()} size="large" variant="text" fullWidth>
                VOLTAR PARA LOGIN
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
      <HiddenForm data-testid="Form" ref={formRef} method="POST" action={FUNDER_PATH}>
        <input value={csrfToken} name="authenticity_token" readOnly />
        <input value={recaptchaToken} name="investidor[g_recaptcha_response]" readOnly />
        <input value={passwordConfirmation} name="investidor[password_confirmation]" readOnly />
        <input value={commonInformation.phone} name="investidor[phone]" readOnly />
        <input value={commonInformation.phoneExtension} name="investidor[branch_line]" readOnly />
        <input value={commonInformation.cellphone} name="investidor[cellphone]" readOnly />
        <input value={userType} name="investidor[kind]" readOnly />
        <input value={password} name="investidor[password]" readOnly />
        {userType === PF_USER_TYPE ? (
          <>
            <input value={pfInformation.name} name="investidor[name]" readOnly />
            <input value={pfInformation.email} name="investidor[email]" readOnly />
            <input value={pfInformation.cpf} name="investidor[cnpj]" readOnly />
          </>
        ) : (
          <>
            <input value={pjInformation.contactName} name="investidor[name]" readOnly />
            <input value={pjInformation.email} name="investidor[email]" readOnly />
            <input value={pjInformation.name} name="investidor[company]" readOnly />
            <input value={pjInformation.tradeName} name="investidor[tradeName]" readOnly />
            <input value={pjInformation.cnpj} name="investidor[cnpj]" readOnly />
          </>
        )}
        <input type="submit" />
      </HiddenForm>
    </Container>
  );
};

const View = ({
  reCaptchaSecret,
  store: {
    investidorSignUp: { flashes },
  },
}) => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSecret} scriptProps={{ async: true }}>
    <SaltiThemeWrapper>
      <FunderRegister errors={flashes} />
    </SaltiThemeWrapper>
  </GoogleReCaptchaProvider>
);

FunderRegister.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

FunderRegister.defaultProps = {
  errors: [],
};

View.propTypes = {
  reCaptchaSecret: PropTypes.string.isRequired,
  store: {
    investidorSignUp: { flashes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)) },
  },
};

View.defaultProps = {
  store: {
    investidorSignUp: { flashes: [] },
  },
};

export default View;
